<template>
  <div class="error">

    <lottie :options="loadingOptions" style="width:600px;height:600px;"/>
    <div class="button_404">
      <el-button type="success" @click="toHome('/')" class="common_btn-defalut ">返回首页</el-button>
    </div>

  </div>
</template>

<script>
import lottie from 'vue-lottie'
import Bus from '@/utils/Bus'
const animationData = require('./lottie-json/404.json')

export default {
  components: { lottie },
  data () {
    return {
      loadingOptions: { animationData: animationData },
    }
  },
  methods: {
    // 登出
    toHome (val) {
      Bus.$emit("toCompany", 0, "子组件向兄弟组件传值");    //存 Bus.$emit
      this.$router.push(val)
    }
  },
  created() {
    Bus.$emit("toCompany", 9, "子组件向兄弟组件传值");    //存 Bus.$emit
  }
}
</script>

<style lang="less" scoped>
@import url('./common.less');
.button_404 {
  position: absolute;
  top: 200px;
  right: 50px;
}

</style>
